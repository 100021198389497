import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Script from 'next/script';
import { defaultTheme, TypographyOld as Typography, Button, Icon } from '@clikaliatech/clikalia-ui';
import useRoutesI18n from '@hooks/useRoutesI18n';
import { useRouter } from 'next/router';
import InputAddressAutocomplete from '@components/common/InputAddressAutocomplete';

import googlePlacesService from '../../../api/googlePlaces.service';
import { HomeAddressProps } from './HomeAddress.types';
import {
  HomeAddressBackgroundImage,
  HomeAddressWrapper,
  HomeAddressBackWrapper,
  DividerStyled,
  InputFooterWrapper,
  HomeAddressStepsWrapper,
  HomeAddressInputWrapper,
} from './HomeAddress.styled';

const GOOGLE_API_KEY = process.env.AIzaSyDl3tmANGg3wPprvJi2ak0Xb8JcN7tz9VA;

const HomeAddress: React.FC<HomeAddressProps> = ({ backgroundImage, onBack }) => {
  const [search, setSearch] = useState('');
  const [selectedSuggestion, setSelectedSuggestion] = useState<string | null>(null);

  const { t } = useTranslation('acquisitions');

  const hasValidSearch = !!search;
  const hasValidSelectedSuggestion = !!selectedSuggestion;
  const isFooterVisible =
    (!hasValidSearch || hasValidSelectedSuggestion) && search === selectedSuggestion;
  const showCta = hasValidSearch && hasValidSelectedSuggestion && search === selectedSuggestion;

  const routes = useRoutesI18n();
  const router = useRouter();

  const steps = [
    {
      step: 1,
      description: t('acquisitions.home.selectDirection.inputLabelOne'),
    },
    {
      step: 2,
      description: t('acquisitions.home.selectDirection.inputLabelTwo'),
    },
    {
      step: 3,
      description: t('acquisitions.home.selectDirection.inputLabelThree'),
    },
  ];

  const handleOnSelect = useCallback((street: string) => {
    setSelectedSuggestion(street);
  }, []);

  const initLocationMap = useCallback(() => {
    googlePlacesService.initLocationMap();
  }, []);

  if (typeof window !== 'undefined') {
    window.initLocationMap = initLocationMap;
  }

  useEffect(() => {
    if (typeof google !== 'undefined' && !googlePlacesService.getLocationMap()) {
      initLocationMap();
    }

    return () => googlePlacesService.removeLocationMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const validateDifferentSearch = () => {
      if (hasValidSelectedSuggestion && selectedSuggestion?.length > search.length) {
        setSelectedSuggestion(null);
      }
    };

    validateDifferentSearch();
  }, [search, selectedSuggestion, hasValidSelectedSuggestion]);

  const handleClick = useCallback(() => {
    router.push(routes.form);
  }, [router, routes.form]);

  return (
    <>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&channel=1&libraries=places&callback=initLocationMap`}
        strategy="afterInteractive"
      />
      <HomeAddressWrapper>
        <HomeAddressBackgroundImage
          src={backgroundImage}
          alt="Home address background"
          sizes="100vw"
        />
        <HomeAddressBackWrapper onClick={onBack}>
          <Icon name="chevron-left" color={Icon.Colors.WHITE} variant={Icon.Variants.SHARP} />
          <Typography color={defaultTheme.palette.white} type={Typography.Types.B2Medium}>
            {t('acquisitions.home.selectDirection.backLabel')}
          </Typography>
        </HomeAddressBackWrapper>
        <HomeAddressInputWrapper>
          <InputAddressAutocomplete
            name="address"
            placeholder={t('acquisitions.home.selectDirection.inputPlaceholder')}
            onChange={setSearch}
            value={search}
            onSelect={handleOnSelect}
          />
        </HomeAddressInputWrapper>
        <InputFooterWrapper visible={isFooterVisible}>
          {showCta ? (
            <Button
              size={Button.Sizes.Large}
              variant={Button.Variants.Primary}
              onClick={handleClick}
              testId="initial-offer"
              id="initial-offer"
            >
              {t('acquisitions.home.selectDirection.callToAction')}
            </Button>
          ) : (
            <HomeAddressStepsWrapper>
              {steps.map((elem, index) => (
                <React.Fragment key={elem.step}>
                  <Typography type={Typography.Types.B2Medium} color={defaultTheme.palette.white}>
                    {`${elem.step}. ${elem.description}`}
                  </Typography>
                  {index !== steps.length - 1 && <DividerStyled />}
                </React.Fragment>
              ))}
            </HomeAddressStepsWrapper>
          )}
        </InputFooterWrapper>
      </HomeAddressWrapper>
    </>
  );
};

export default HomeAddress;
